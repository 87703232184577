import React, {useEffect, useState} from "react"
import Helmet from "react-helmet";
import queryString from "query-string"
import {Link, graphql} from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import PostDetail from "../../components/postDetail";

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
        allMicrocmsPost {
            edges {
                node {
                    postId
                    title
                    eyecatch {
                        url
                    }
                    publishedAt(formatString: "YYYY年MM月DD日")
                }
            }
        }
    }
`

const extractHeadings = (htmlString) => {
    let headings = [];
    htmlString.replace(/<h(\d) id="(.+?)">(.+?)<\/h\d>/gi, (_, level, id, title) => {
        headings.push({
            level: parseInt(level),
            id: id,
            title: title
        })
    })
    return headings
}

const replaceBox = (htmlString) => {
    return htmlString.replace(/<h5.+?>===<\/h5>([\s\S]+?)<h5.+?>===<\/h5>/gi, (all, content) => {
        return `<div class="postBox">${content}</div>`
    })
}

const replaceImage = (htmlString) => {
    return htmlString.replace(/<img(.*?)src="(https:\/\/images\.microcms-assets\.io.+?)\.(jpg|jpeg|png)(\?w=\d+&amp;h=\d+)?"(.*?)>/gi, (all, prefix, url, ex, query, postfix) => {
        let src
        if (query) {
            src = `${url}.${ex}${query}&auto=format&auto=compress&fm=webp`
        } else {
            src = `${url}.${ex}?auto=format&auto=compress&fm=webp&max-w=1000&max-h=1000&fit=fill`
        }
        return `<img${prefix}src="${src}"${postfix}/>`
    })
}

const replaceAffiliate = (htmlString) => {
    const escapeHtml = (string) => {
        if (typeof string !== 'string') {
            return string;
        }
        return string.replace(/[&'`"<>]/g, function (match) {
            return {
                '&': '&amp;',
                "'": '&#x27;',
                '`': '&#x60;',
                '"': '&quot;',
                '<': '&lt;',
                '>': '&gt;',
            }[match]
        });
    }
    const truncate = (input) => {
        if (input.length > 18) {
            return input.substring(0, 18) + '...';
        }
        return input;
    };
    return htmlString.replace(/<pre><code>affiliate\n([\s\S]+?)<\/code><\/pre>/gi, (_, text) => {
        const imageResult = /image:\s?(.+)/gi.exec(text)
        const textResult = /text:\s?(.+)/gi.exec(text)
        const amazonResult = /amazon:\s?(.+)/gi.exec(text)
        const rakutenResult = /rakuten:\s?(.+)/gi.exec(text)
        const url = amazonResult ? amazonResult[1] : rakutenResult[1];
        const alt = escapeHtml(truncate(textResult[1]));
        return `
<div class="affiliateLink">
    <a class="imageLink" href="${url}" target="_blank" rel="nofollow sponsored noopener">
      <img src="${imageResult[1]}" alt="${alt}" />
    </a>
    <div>
        <a class="nameLink" href="${url}" target="_blank" rel="nofollow sponsored noopener">${textResult[1]}</a>
        <div class="buttons">
          ${amazonResult ? `<a class="amazonButton" href="${amazonResult[1]}" target="_blank"
                                           rel="nofollow sponsored noopener">Amazon</a>` : ''}
          ${rakutenResult ? `<a class="rakutenButton" href="${rakutenResult[1]}" target="_blank"
                                           rel="nofollow sponsored noopener">楽天市場</a>` : ''}
        </div>
    </div>
</div>
`
    })
}

const replaceEscapedHtmlTag = (htmlString) => {
    return htmlString.replace(/<pre><code>([\s\S]+?)<\/code><\/pre>/gi, (_, htmlTag) => {
        return htmlTag
            .replace(/<br>|&nbsp;/gi, "")
            .replace(/&lt;/gi, "<")
            .replace(/&gt;/gi, ">")
            .replace(/&amp;/gi, "&")
            .replace(/&quot;/gi, `"`)
    })
}

const convertInternalLink = (body, allPosts, siteUrl) => {
    return body.replace(/<a href="\/(.+?)\/(.+?)">(.+?)<\/a>/gi, (all, categorySlug, postId, content) => {
        const {node} = allPosts.find(({node}) => node.postId === postId);
        if (node) {
            return `<a class="internalLink" href="/${categorySlug}/${postId}"><img src="${node.eyecatch.url}?auto=format&auto=compress&fm=webp&h=160&ar=1:1&fit=crop&fp-y=0.5" alt="画像" /><span class="wrapper"><span class="date">${node.publishedAt}</span><span class="title">${node.title}</span><span class="siteUrl">${(new URL(siteUrl).hostname)}</span></span></a>`
        } else {
            return `<a  href="/${categorySlug}/${postId}">${content}</a>`
        }
    })
}

const convert = (data, body) => {
    const body2 = replaceBox(body);
    const body3 = replaceImage(body2);
    const body4 = replaceAffiliate(body3);
    const body5 = replaceEscapedHtmlTag(body4);
    return convertInternalLink(body5, data.allMicrocmsPost.edges, data.site.siteMetadata.siteUrl)
}

const DraftPage = ({data, location}) => {
    const {serviceId, postId, draftKey, apiKey} = queryString.parse(location.search);
    const [post, setPost] = useState(null);

    useEffect(() => {
        fetch(`https://${serviceId}.microcms.io/api/v1/post/${postId}?draftKey=${draftKey}`, {
            headers: {
                'X-API-KEY': apiKey,
            },
        })
            .then(res => res.json())
            .then(res => {
                setPost(res)
                if (window.instgrm) {
                    window.instgrm.Embeds.process()
                }
            });
    }, []);

    if (post === null) {
        return null;
    }

    post.body = convert(data, post.body);
    post.headings = extractHeadings(post.body);

    return (
        <Layout>
            <Seo title={post.title}/>
            <Helmet>
                <script async src="//www.instagram.com/embed.js"/>
            </Helmet>
            <nav className="breadcrumb">
                <ol>
                    <li>
                        <Link to="/">トップ</Link>
                    </li>
                    <li>
                        <Link to={`/${post.category.slug}`}>{post.category.name}</Link>
                    </li>
                </ol>
            </nav>
            <PostDetail post={post}/>
        </Layout>
    )
}

export default DraftPage
