// extracted by mini-css-extract-plugin
export var postWrapper = "postDetail-module--postWrapper--14pu2";
export var title = "postDetail-module--title--2T_0m";
export var eyeCatch = "postDetail-module--eyeCatch--1cUE0";
export var postMeta = "postDetail-module--postMeta--3JMS0";
export var publishedAtIcon = "postDetail-module--publishedAtIcon--1MUeF";
export var publishedAt = "postDetail-module--publishedAt--zArO5";
export var tagsWrapper = "postDetail-module--tagsWrapper--26775";
export var tags = "postDetail-module--tags--s2c_b";
export var tag = "postDetail-module--tag--3-qae";
export var headings = "postDetail-module--headings--2yMyq";
export var postBody = "postDetail-module--postBody--241x9";