import React from "react"
import {Link} from "gatsby"
import * as styles from "./postDetail.module.css"

const PostDetail = ({post}) => {
    return (
        <article className={styles.postWrapper}>
            <h1 className={styles.title}>{post.title}</h1>

            <div className={styles.postMeta}>
                <span className={styles.publishedAt}>{post.publishedAt}</span>
            </div>

            <img className={styles.eyeCatch}
                 src={`${post.eyecatch.url}?auto=format&fm=webp&max-w=1000`}
                 alt={post.title}
            />

            {post.headings.length === 0 ? null :
                <div className={styles.headings}>
                    <h2>目次</h2>
                    <ol>
                        {post.headings.filter((h) => h.level === 2).map((heading) => {
                            return (
                                <li key={heading.id}>
                                    <a href={`#${heading.id}`}>{heading.title}</a>
                                </li>
                            )
                        })}
                    </ol>
                </div>
            }

            <div
                className={styles.postBody}
                dangerouslySetInnerHTML={{
                    __html: `${post.body}`,
                }}
            />

            {post.tag.length > 0 ?
                <div className={styles.tagsWrapper}>
                    <ul className={styles.tags}>
                        {post.tag.map((tag) => {
                            return (
                                <li className={styles.tag} key={tag.slug}>
                                    <Link to={`/tags/${tag.slug}`}>#{tag.name}</Link>
                                </li>
                            )
                        })}
                    </ul>
                </div> : null}
        </article>
    )
}

export default PostDetail
